import React from "react";
function Achievements() {
  return (
    <section
      id="achievements"
      className="scroll-mt-[var(--navbar-height)] p-5 border-t flex flex-col [&>*]:text-black"
    >
      <h2 className="text-3xl font-bold text-center mb-4 md:text-5xl">
        السجل البرلماني
      </h2>
      <div className="flex flex-col md:flex-row gap-10 text-lg md:text-3xl ">
        <div className="w-full md:w-[33%]">
          <h3 className="text-secondary font-bold text-center text-2xl md:text-3xl">
            أبرز الأسئلة البرلمانية
          </h3>
          <p className="text-secondary text-center mb-5 text-sm  b ">
            من 129 سؤالاً
          </p>
          <ul className="list-disc mr-5 text-black [&>li]:mb-3 text-justify">
            <li>عدد اللجان التي شكلت حول شبهات تعدي على المال العام</li>
            <li>تكويت الوظائف الاشرافية والعامة في الجمعيات التعاونية</li>
            <li>
              عدد الموظفين الذين قدموا تظلمات لجهات قضائية بشأن عدم شغلهم لإحدى
              الوظائف الاشرافية والقيادية
            </li>
            <li>الشركات التي نفذت مشاريع البيوت الحكومية</li>
            <li>نقص الكوادر الطبية الوطنية في المستشفيات والمراكز الطبية</li>
            <li>
              القرارات الصادرة من الهيئة العامة للقوى العاملة الخاصة بتحديد نسب
              العمالة الوطنية للعاملين في القطاع الخاص
            </li>
            <li>متوسط الكثافة الطلابية داخل الفصول في المدراس</li>
            <li>
              خطة وزارة التربية لسد العجز في الوظائف التربوية للعام الدراسي
              2024-2025
            </li>
            <li>
              الاستراتيجية والرؤية المستقبلية لوزارة التجارة والصناعة للمشاركة
              في تنويع مصادر الدخل وزيادة ايرادات الدولة
            </li>
            <li>قيمة العجز في الميزانية السنوية للوزارات والجهات التابعة </li>
          </ul>
        </div>
        <div className="w-full md:w-[33%]">
          <h3 className="text-secondary font-bold text-center text-2xl md:text-3xl">
            أبرز الاقتراحات بقانون
          </h3>
          <p className="text-secondary text-center mb-5 text-sm">
            من 139 اقتراحاً
          </p>
          <ul className="list-disc mr-5 text-black [&>li]:mb-3 text-justify">
            <li>تكويت الوظائف العامة في الدولة </li>
            <li>قانون التأمينات الاجتماعية (زيادة المعاشات التقاعدية)</li>
            <li>
              منح الموظفين إجازات دراسية أو إيفادهم في بعثات أو في دورات تدريبية
              بالداخل أو بالخارج بمرتب كامل
            </li>
            <li>إنشاء محكمة خاصة للفصل بالطعون الانتخابية</li>
            <li>تنظيم الوكالة العقارية</li>
            <li>
              تأسيس شركة متخصصة في صيانة المصافي النفطية ومحطات انتاج الطاقة
              الكهربائية
            </li>
            <li>التأمين الصحي على المواطنين المتقاعدين</li>
            <li>حقوق الأشخاص ذوي الإعاقة</li>
            <li>
              إنشاء مستشفى لعلاج وتأهيل المرضى من الإدمان على المواد المخدرة
            </li>
            <li>
              لا يجوز سحب الجنسية الكويتية من الكويتي الذي كسبها إلا في حالات
              محددة
            </li>
            <li>العمل الخيري والإنساني</li>
            <li>إنشاء مدينة طبية متكاملة</li>
            <li>ابتعاث خريجي المعهد الديني</li>
            <li>إنشاء الصندوق الوطني لمعالجة آثار عمليات النصب العقاري</li>
            <li>تأسيس شركة متخصصة في الخدمات النفطية</li>
          </ul>
        </div>
        <div className="w-full md:w-[33%]">
          <h3 className="text-secondary font-bold text-center text-2xl md:text-3xl">
            أبرز الاقتراحات برغبة
          </h3>
          <p className="text-secondary text-center mb-5 text-sm">من 94 رغبة</p>
          <ul className="list-disc mr-5 text-black [&>li]:mb-3 text-justify">
            <li>تعديل العلاوة الدورية للموظفين بنسبة 100%</li>
            <li>انشاء مركز طبي متخصص لعلاج العقم والمساعدة على الانجاب</li>
            <li>
              منح جميع الموظفين الإداريين بوزارة الصحة (بدل عدوى) اسوة بالكوادر
              الطبية
            </li>
            <li>
              إلغاء جميع قرارات مجلس الخدمة بشأن منع الجميع بين الوظيفة الحكومية
              والدراسة
            </li>
            <li>
              اسقاط مديونية صرف بدل الإيجار عن جميع اصحاب البيوت الحكومية الذين
              استبدلوا ببيوتهم طلبات اسكانية
            </li>
            <li>استمرار صرف دعم العمالة لموظفي القطاع الخاص</li>
            <li>
              منح المرأة الحاضنة حق الانتفاع والملكية للسكن المخصص لها ولأبنائها
              من قبل المؤسسة العامة للرعاية السكنية
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Achievements;
