import React from "react";
function Statement() {
  return (
    <section
      id="statement"
      className="scroll-mt-[var(--navbar-height)] p-5 border-t border-secondary flex flex-col md:flex-row [&>*]:text-black"
    >
      <div className="w-full">
        <h2 className="text-3xl md:text-5xl font-bold text-center mb-4">
          بيان خوض الانتخابات
        </h2>
        <main className="[&>p]:mb-3 [&>p]:text-justify md:w-[500px] md:mx-auto">
          <h3 className="text-xl font-bold text-center mb-4">
            بسم الله الرحمن الرحيم
          </h3>
          <p className="text-lg md:text-2xl">
            بعد أن صنع الشعب القرار، واختار ممثليه الذين تشرفت أن أكون بينهم،
            وسعينا جاهدين على أن نضع حداً لسلسلة عمليات الفساد الممنهج، في أجهزة
            الدولة ومؤسساتها، وعملنا على إقرار القوانين، التي كان ينتظرها الشعب
            الكويتي كافة، وسرنا على خارطة عمل تشريعية اتفقت عليها أغلبية المجلس.
          </p>
          <p className="text-lg md:text-2xl">
            فإني أعلن خوضي انتخابات مجلس الأمة 2024، ممثلاً عن الدائرة
            الانتخابية الثانية، رافعاً شعار لنكمل ما بدأناه لأننا نؤمن بأن هذا
            الشعب ينتظر منا الكثير.
          </p>
          <p className="text-lg md:text-2xl">
            وسنعمل على أن نكون عند حسن ظنّه كما عهدنا دائم.
          </p>
          <p className="text-lg md:text-2xl">والله ولي التوفيق.</p>
        </main>
      </div>
    </section>
  );
}

export default Statement;
