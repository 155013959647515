import React from "react";

function Committees() {
  return (
    <section
      id="committees"
      className="scroll-mt-[var(--navbar-height)] p-5 border-t border-secondary flex flex-col md:flex-row [&>*]:var(--color-text)"
    >
      <h2 className="text-3xl font-bold text-center mb-4 md:text-5xl">
        لجان المجلس
      </h2>
      <div className="md:w-[50%] w-full mb-5 md:mb-0">
        <h3 className="text-secondary font-bold text-center text-2xl md:text-3xl">
          دور الانعقاد الأول
        </h3>
        <table className="w-full table-auto text-right md:text-center text-lg md:text-2xl">
          <thead>
            <tr>
              <th>اللجنة</th>
              <th>المنصب</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>الموارد البشرية</td>
              <td>مقرر</td>
            </tr>
            <tr>
              <td>إعداد مشروع الجواب على الخطاب الأميري</td>
              <td>مقرر</td>
            </tr>
            <tr>
              <td>الشباب والرياضة</td>
              <td>مقرر</td>
            </tr>
            <tr>
              <td>الشئون المالية والاقتصادية</td>
              <td>عضو</td>
            </tr>
            <tr>
              <td>الشئون الصحية والاجتماعية والعمل</td>
              <td>عضو</td>
            </tr>
            <tr>
              <td>غير محددي الجنسية</td>
              <td>عضو</td>
            </tr>
            <tr>
              <td>شؤون النفط والطاقة</td>
              <td>عضو</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="md:w-[50%] w-full ">
        <h3 className="text-secondary font-bold text-center text-2xl md:text-3xl">
          دور الانعقاد الثاني
        </h3>
        <table className="w-full table-auto text-right md:text-center text-lg md:text-2xl">
          <thead>
            <tr>
              <th>اللجنة</th>
              <th>المنصب</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>الشئون الصحية والاجتماعية والعمل</td>
              <td>مقرر</td>
            </tr>
            <tr>
              <td>شئون الشباب والرياضة</td>
              <td>مقرر</td>
            </tr>
            <tr>
              <td>الشئون المالية والاقتصادية</td>
              <td>عضو</td>
            </tr>
            <tr>
              <td>الإسكان والعقار</td>
              <td>عضو</td>
            </tr>
            <tr>
              <td>شؤون النفط والطاقة</td>
              <td>عضو</td>
            </tr>
            <tr>
              <td>التحقيق في محاور استجواب وزير الأشغال</td>
              <td>عضو</td>
            </tr>
            <tr>
              <td>المشتركة بين لجنتي المالية والموارد البشرية</td>
              <td>عضو</td>
            </tr>
            <tr>
              <td>شئون الشباب والرياضة</td>
              <td>عضو</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default Committees;
