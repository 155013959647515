import React from "react";
import officialPhoto from "../assets/images/badernashmi-photo.png";
import slogan from "../assets/images/slogan.png";
function FirstFold() {
  return (
    <section
      id="intro"
      className="w-full flex flex-col overflow-hidden mt-[var(--navbar-height)] h-screen md:flex-row"
    >
      <div className="h-[100%] w-[100%] order-1 md:w-[50%] z-10 flex flex-row justify-end items-end">
        <img
          className="h-[100%] object-cover md:w-auto md:h-[80%]"
          src={officialPhoto}
          alt="صورة بدر نشمي العنزي"
        />
      </div>
      <div className="h-[40%] flex flex-col w-full justify-center items-center [&>*]:mb-5 md:w-[50%] md:h-[100%]">
        <img
          className="w-[40%] md:w-[40%] h-auto z-10"
          src={slogan}
          alt="شعار 2024"
        />
      </div>
    </section>
  );
}

export default FirstFold;
