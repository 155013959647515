import xIcon from "../assets/images/x-social-media-icon.png";
import instagramIcon from "../assets/images/instagram-icon.svg";
import raqibIcon from "../assets/images/raqib-logo.png";
const links = [
  { name: "اكس", link: "https://twitter.com/BaderNashmi", icon: xIcon },
  {
    name: "انستغرام",
    link: "https://www.instagram.com/bdrnashmi",
    icon: instagramIcon,
  },
  {
    name: "صفحة راقب خمسين",
    link: "https://www.raqib50.com/parliaments/14/members/292/attendance_and_committees",
    icon: raqibIcon,
  },
];
export default links;
