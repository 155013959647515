import React from "react";

function Votes() {
  return (
    <section
      id="votes"
      className="scroll-mt-[var(--navbar-height)] p-5 border-t border-secondary flex flex-col [&>*]:text-black"
    >
      <h2 className="text-3xl font-bold text-center mb-4 md:text-5xl">
        تصويتات ومواقف
      </h2>
      <div className="w-full mb-5 md:mb-5">
        <h3 className="text-secondary font-bold text-center text-2xl md:text-3xl">
          القوانين المنجزة
        </h3>
        <table className="md:w-[60%] md:m-auto w-full table-auto text-right md:text-center text-lg md:text-2xl">
          <thead>
            <tr>
              <th>القانون</th>
              <th>تاريخ الجلسة</th>
              <th>التصويت</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b md:border-0 border-black">
              <td>إضافة ربات البيوت إلى عافية</td>
              <td>13/07/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>تأسيس شركات لإنشاء مدن سكنية</td>
              <td>13/07/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>تعديل قانون المحكمة الدستورية</td>
              <td>13/07/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>
                قانون في شأن انتخابات اعضاء مجلس الامة (مفوضية الانتخابات)
              </td>
              <td>01/08/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>زيادة الحد الأدنى للمعاشات التقاعدية</td>
              <td>14/11/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>تعديل قانون تنظيم غرفة تجارة وصناعة الكويت</td>
              <td>13/07/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>قانون إصدار قانون الصناعة</td>
              <td>28/11/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>
                تعديل قانون التأمينات واستبدال غرفة التجارة بالقوى العاملة
              </td>
              <td>28/11/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>تعديل قانون حقوق الأشخاص ذوي الإعاقة</td>
              <td>29/11/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>
                قانون إصدار قانون الإجراءات والمحاكمات الجزائية فيما يتعلق بمدد
                رد الاعتبار
              </td>
              <td>12/12/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>إلغاء شرط الوكيل المحلي</td>
              <td>12/12/2023</td>
              <td>موافق</td>
            </tr>
            <tr className="border-b md:border-0 border-black">
              <td>مشروع قانون بتعيين مخصصات رئيس الدولة</td>
              <td>07/02/2024</td>
              <td>موافق</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="w-full mb-5 md:mb-0">
        <h3 className="text-secondary font-bold text-center text-2xl md:text-3xl">
          مواقف واحصاءات
        </h3>
        <ul className="list-disc mr-5 md:w-[40%] md:m-auto text-black [&>li]:mb-3 text-justify text-lg md:text-3xl">
          <li>رفض النائب شطب كلمة النائب د. عبد الكريم الكندري من المضبطة</li>
          <li>معدل حضور الجلسات 100% </li>
          <li>
            جاء في المركز الثاني في تقديم الاقتراحات بقانون بـ 139 اقتراحاً{" "}
          </li>
          <li>جاء في الترتيب الرابع في تقديم الاقتراحات برغبة بـ 94 رغبة </li>
          <li>جاء في الترتيب الخامس في توجيه الأسئلة للوزراء بـ 129 سؤالاً</li>
          <li>طلب مناقشة الانتهاكات الصهيونية في غزة</li>
        </ul>
      </div>
    </section>
  );
}

export default Votes;
