import React from "react";
import interviews from "../data/interviews";
function Interviews() {
  return (
    <section
      id="interviews"
      className="
      scroll-mt-[var(--navbar-height)] p-5 border-t border-secondary
      flex flex-col [&>*]:text-white 
      md:grid md:grid-cols-3 md:h-[300px] gap-5"
    >
      <div className="flex justify-center items-center">
        <h2 className="text-3xl md:text-5xl font-bold text-center mb-4 text-black">
          مرئيات
        </h2>
      </div>
      {interviews.map((el) => (
        <div
          className="h-[150px] md:h-auto border-4 border-secondary rounded-xl cursor-pointer bg-cover bg-center flex items-center justify-center [&>*]:text-secondary [&>*]:text-2xl font-bold"
          style={{ backgroundImage: `url(${el.screenshot})` }}
          onClick={() =>
            window.open(`${el.link}`, "_blank", "noopener noreferrer")
          }
        >
          <span>{el.name}</span>
        </div>
      ))}
    </section>
  );
}

export default Interviews;
