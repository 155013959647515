import dawaer from "../assets/images/nashmi-dawaer.png";
import modakhalat from "../assets/images/bader-nashmi-modakhalat.png";
const interviews = [
  {
    name: "مقابلة برنامج الدوائر الخمس",
    link: "https://youtu.be/Rr22cwh6mTo?si=WLpYav5AcShtLkhq",
    screenshot: dawaer,
  },
  {
    name: "مداخلات مجلس الأمة",
    link: "https://www.youtube.com/playlist?list=PL8zrcll5OMgvHuFDUv1fyOniIq4QRZN-V",
    screenshot: modakhalat,
  },
];
export default interviews;
