import FirstFold from "./sections/FirstFold";
import Resume from "./sections/Resume";
import Achievements from "./sections/Achievements";
import Statement from "./sections/Statement";
import Footer from "./sections/Footer";
import Links from "./sections/Links";
import Navbar from "./components/Navbar";
import Committees from "./sections/Committees";
import Votes from "./sections/Votes";
import Interviews from "./sections/Interviews";
import grain from "./assets/images/grain.png";
function App() {
  return (
    <div
      className="bg-primary bg-opacity-100"
      style={{ backgroundImage: `url(${grain})` }}
    >
      <Navbar />
      <FirstFold />
      <Statement />
      <Committees />
      <Achievements />
      <Votes />
      <Resume />
      <Interviews />
      <Links />
      <Footer />
    </div>
  );
}

export default App;
